import If from "components/If"
import useLogin from "./hooks"
import FormLogin from "./components/FormLogin"
import CallbackLogin from "./components/CallbackLogin"

const Login = () => {
  const { code, removeCode, oauth } = useLogin()

  return (
    <div className="login">
      <If
        condition={!!code}
        renderIf={
          <CallbackLogin
            code={code}
            onFailure={oauth.setMessage}
            redirectToLogin={removeCode}
          />
        }
        renderElse={<FormLogin oauth={oauth} />}
      />
    </div>
  )
}

export default Login
