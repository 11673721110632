import { Link } from "gatsby"
import If from "components/If"
import useFormLogin from "./hooks"
import classnames from "classnames"
import Button from "components/Button"
import Form from "components/Forms/Form"
import TextDivider from "../TextDivider"
import { IU_AUTH_URL } from "./constants"
import Input from "components/Forms/Input"
import { values, validation } from "./form"
import AuthError from "components/Auth/Error"
import AuthFeedback from "components/Auth/Feedback"
import FeatureToggle from "components/FeatureToggle"

import "./FormLogin.styl"

const FormLogin = ({ oauth }) => {
  const { errors, onSubmit, onConcluded, isRequestingAPI } = useFormLogin({
    oauth,
  })

  const isForm = !errors.locked && !errors.expired

  return (
    <>
      <If condition={isForm}>
        <h1
          className={classnames("form-login__title logged-out__title", {
            "form-login__title--error": errors.message,
          })}
          data-test="title"
        >
          Acesse sua conta
        </h1>
        <If condition={errors.message || oauth?.message}>
          <AuthError
            data-test="error"
            message={errors.message || oauth?.message}
          />
        </If>
        <Form
          className="form-login"
          onSubmit={onSubmit}
          defaultValues={values}
          schema={validation}
        >
          <Button
            external
            variant="group"
            data-test="iu-auth"
            className="form-login__iu-auth"
            link={IU_AUTH_URL}
          >
            Login com IU Acessos
          </Button>

          <TextDivider className="form-login__divider">ou</TextDivider>
          <Input
            className="form-login__input"
            name="login"
            placeholder="Digite seu email"
            data-test="email"
          />
          <Input
            className="form-login__input"
            name="password"
            type="password"
            data-test="password"
            placeholder="Digite sua senha"
          />
          {({ state: { isDisabled } }) => (
            <Button
              className="form-login__submit"
              type="submit"
              size="big"
              disabled={isDisabled || isRequestingAPI}
              data-test="submit"
            >
              Acessar conta
            </Button>
          )}
        </Form>
        <Link
          className="form-login__forgot-password"
          to={ROUTES.forgotPassword.path}
          data-test="link--forgot-password"
        >
          Esqueci minha senha
        </Link>
      </If>
      <If condition={errors.locked}>
        <AuthFeedback
          icon="lock"
          title="Senha bloqueada"
          description="Você errou sua senha 5 vezes e por isso ela foi bloqueada. Entre em contato com o administrador"
          button="Concluído"
          onClick={onConcluded}
          data-test="auth-feedback"
        />
      </If>
      <If condition={errors.expired}>
        <AuthFeedback
          icon="lock"
          title="Senha expirada"
          description="Por seguraça sua senha expira a cada 3 meses, cadastre uma nova senha para acessar o sistema"
          button="Concluído"
          onClick={onConcluded}
          data-test="auth-feedback"
        />
      </If>
    </>
  )
}

export default FormLogin
