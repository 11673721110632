import classNames from "classnames"
import "./TextDivider.styl"

const TextDivider = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames("text-divider", {
        [className]: className,
      })}
      {...props}
    >
      <div className="text-divider__text">{children}</div>
    </div>
  )
}

export default TextDivider
