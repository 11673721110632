import Button from "components/Button"
import Icon from "components/Icon"

import "./AuthFeedback.styl"

const AuthFeedback = ({
  icon,
  title,
  description,
  button,
  onClick,
  ...props
}) => (
  <div className="auth-feedback" {...props}>
    <Icon name={icon} />
    <h1 className="auth-feedback__title">{title}</h1>
    <p className="auth-feedback__description">{description}</p>
    <Button variant="ghost" onClick={onClick}>
      {button}
    </Button>
  </div>
)

export default AuthFeedback
