/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

import services from "services"
import { handlePermissionsStorage } from "../../utils"

const useCallbackLogin = ({ code, redirectToLogin, onFailure }) => {
  const handleFailure = (message = "") => {
    onFailure(message)
    redirectToLogin()
  }

  const handleOuath = (data) => {
    const { error, message } = data
    if (!!error) return handleFailure(message)

    handlePermissionsStorage(data)
  }

  useEffect(() => {
    if (!code) return

    services.auth.oauth({ code }).then(handleOuath)
  }, [code])
}

export default useCallbackLogin
