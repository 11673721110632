import auth from "utils/auth"
import services from "services"
import storage from "utils/storage"
import { navigate } from "gatsby"

const goToHome = () => navigate(ROUTES.home.path)

const handleStorage = (data) =>
  storage.session.setItem("permissions", JSON.stringify(data))

export const handlePermissionsStorage = ({ token } = {}) => {
  if (token) {
    const user = auth.getDataFromToken(token)
    services.user.permissions(user).then(handleStorage).then(goToHome)
  }
}
