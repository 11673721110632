import Loader from "components/Loader"

import useCallbackLogin from "./hooks"
import "./CallbackLogin.styl"

const CallbackLogin = ({ code, redirectToLogin, onFailure }) => {
  useCallbackLogin({ code, redirectToLogin, onFailure })

  return (
    <section className="callback-login">
      <span className="callback-login__message">Verificando...</span>
      <Loader variant="component" active />
    </section>
  )
}

export default CallbackLogin
