import * as Yup from "yup"

export const values = {
  login: "",
  password: "",
}

export const validation = Yup.object().shape({
  login: Yup.string()
    .trim()
    .required("Campo obrigatório")
    .email("E-mail inválido"),
  password: Yup.string().required("Campo obrigatório"),
})
