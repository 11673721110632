import { useState } from "react"
import services from "services"
import { handlePermissionsStorage } from "../../utils"

const initialStates = {
  expired: false,
  locked: false,
  message: false,
}

const useFormLogin = ({ oauth }) => {
  const [errors, setErrors] = useState(initialStates)
  const [isRequestingAPI, setIsRequestingAPI] = useState(false)

  const onSubmit = (formData) => {
    setIsRequestingAPI(true)
    oauth.resetMessage()

    services.auth
      .login(formData)
      .then(handleAuthentication)
      .then(handlePermissionsStorage)
      .then(() => setIsRequestingAPI(false))
  }

  const onConcluded = () => {
    setErrors(initialStates)
  }

  const handleAuthentication = ({ error, token, message }) => {
    if (error && message === "Usuário bloqueado") {
      return setErrors({ ...initialStates, locked: true })
    }

    if (error && message === "Senha expirada") {
      return setErrors({ ...initialStates, locked: false })
    }

    if (error) {
      return setErrors({ ...initialStates, message })
    }

    return { token }
  }

  return {
    errors,
    onSubmit,
    onConcluded,
    isRequestingAPI,
  }
}

export default useFormLogin
